<template lang="pug">
  div.page-statistics
    loading(:active.sync="busy", :is-full-page="true")
    .widget(v-if="filters")
      .widget-header
        h1.title Events
        i.la.la-2x.la-filter.d-flex.align-items-center.mobile(@click="areFiltersOpened = !areFiltersOpened")
      .widget-body
        filters(@submit="loadData()", :default-filters="defaultFilters", :current-filters="filters", :page-name="'events'", :is-opened="areFiltersOpened", @close-filters="areFiltersOpened = false")
          .form-row-main
            ov-date-time-picker(v-model="filters.datePicker")
            select-advertiser(v-model="filters.advertiser_id")
            select-offer(v-model="filters.offer_id")
            select-publisher(v-model="filters.publisher_id")
            select-placement(v-model="filters.placement_id")
            .form-group
              label Event Name
              input.form-control(type="text", placeholder="FirstDeposit", v-model="filters.event_name")
            click-list(v-model="filters.clicks", label="Clicks")
            .form-group.no-label
              b-checkbox(type="checkbox", v-model="filters.conversions_only") Conversions Only

          .form-row-end.desktop
            .form-group
              label &nbsp;
              // button.btn.btn-primary(type="submit") Go
              b-button-group
                b-dropdown(split-button-type="submit", variant="primary", right, split, text="Go")
                  b-dropdown-item(@click="loadData(true)")
                    i.la.la-download
                    | Export

          .w-100.mobile.p-2.border-top.position-sticky.bg-white(style="bottom: 0; z-index: 2;")
            b-button-group.submit-filters-button
              b-dropdown(split-button-type="submit", variant="primary", right, split, text="Go")
                b-dropdown-item(@click="loadData(1)")
                  i.la.la-download.mr-1
                  | Export

        p.clearfix &nbsp;

        paginate(:paginator="paginate", @update-page-size="loadData")

        .ov-table-wrapper
          .tabs.table-tabs
            ul.nav.nav-tabs
              li.nav-item
                a.nav-link(:class="{active:selectedTab==='search'}", @click="selectedTab='search'") Search ({{ paginate.total }})
              li.nav-item
                a.nav-link(:class="{active:selectedTab==='bucket', highlight: bucket.length > 0}", @click="selectedTab='bucket'") Selected
                  span.bucket-size ({{bucket.length}})
          table.table.table-bordered.table-condensed
            thead
              tr
                th
                  span
                    a(href="javascript:void(0);", @click="selectAll()")
                      i.la.la-check-square-o
                    =' | '
                  span
                    a(href="javascript:void(0);", @click="clearBucket()")
                      i.la.la-square-o
                th
                  .text ID
                th
                  .text P
                th
                  .text R
                th
                  .text Advertiser
                th
                  .text Offer
                th
                  .text.has-buttons Placement
                    a.toggle-mode(href="javascript:void(0);", @click="togglePlacementViewMode()")
                      i.la.la-arrows-h
                th
                  .text Publisher
                th
                  .text Subsource
                th
                  .text Appname / Bundle
                th
                  .text Type
                th
                  .text.text-center Payout
                th
                  .text.text-center Cost
                th
                  .text ClickID
                th
                  .text ClickIP
                th
                  .text ClickTime
                th
                  .text EventTime
                th
                  .text ReportTime
                th
                  .text Type / Name
                th
                  .text &nbsp;
                th
                  b-dropdown(text="Actions", size="sm", variant="link")
                    b-dropdown-item(href="#", @click="bulkReport()")
                      i.la.la-bell.mr-2
                      | Report
                    b-dropdown-item(href="#", @click="bulkConvert()")
                      i.la.la-dollar-sign.mr-2
                      | Convert
            tbody
              tr(v-if="records.length===0")
                td(colspan="21") No matching records were found
              tr(v-for="r in (selectedTab==='bucket'?bucket:records)", v-bind:key="r.id",
                :class="{'row-selected': bucketHash[r.id], 'row-success': !r.rejected && r.is_adv_conversion, 'row-info': !r.rejected && r.is_pub_conversion, 'row-danger': r.rejected}")
                td.col-select(:class="{'bucket-row':bucketHash[r.id]}")
                  a(href="javascript:void(0)", @click="toggleBucket(r)")
                    i.la(:class="{'la-check-square':bucketHash[r.id], 'la-stop':!bucketHash[r.id]}")
                td {{ r.id }}
                td {{ r.click && r.click.advanced_privacy ? 'P' : '' }}
                td {{ r.click && r.click.is_reporting ? 'R' : '' }}
                td
                  span(v-if="r.advertiser")
                    entity(type="advertiser", :id="r.advertiser.id", :name.sync="r.advertiser.name", @click="appendEntity(r,'advertiser')")
                td
                  span(v-if="r.offer")
                    entity(type="offer", :id="r.offer.id", :name.sync="r.offer.name", @click="appendEntity(r,'offer')")
                td
                  span(v-if="r.placement")
                    entity(type="placement", :id="r.placement.id", :name.sync="r.placement.name", :mode="placementViewMode", @click="appendEntity(r,'placement')", :data="r.placement")
                td
                  span(v-if="r.publisher")
                    entity(type="publisher", :id="r.publisher.id", :name.sync="r.publisher.name", @click="appendEntity(r,'publisher')")
                td
                  entity(v-if="r.click.sub1", type="sub1", :id="r.click.sub1.id", :name.sync="r.click.sub1.name")
                  entity(v-if="r.click.sub2", type="sub2", :id="r.click.sub2.id", :name.sync="r.click.sub2.name")
                td
                  entity(v-if="r.click.appname", type="appname", :id="r.click.appname.id", :name.sync="r.click.appname.name")
                  =' / '
                  entity(v-if="r.click.bundle", type="bundle", :id="r.click.bundle.id", :name.sync="r.click.bundle.name")
                td {{ r.click.request_type === 1 ? 'Impression' : 'Click' }}
                //td
                //div.adv-info(v-if="r.click && r.click.adv_params")
                //	div.adv-appname {{ r.click.adv_params.appname }}
                //	div.adv-bundle {{ r.click.adv_params.bundle }}
                //	div.adv-subsource {{ r.click.adv_params.sub1 }}
                td.text-center {{ r.payout | toCurrency }}
                td.text-center {{ r.cost | toCurrency }}
                td(style="max-width: 220px;")
                  router-link.btn-edit(v-if="r.click", v-b-tooltip.hover.bottom, title="View",
                    :to="{name:'click-view', params: {id: r.click.id}}") {{ r.click.id }}
                  div.text-muted(v-if="r.click")
                    small.text-muted(style="word-break: break-all;") {{ r.click.aff_clickid }}
                td
                  span(v-if="r.click") {{ r.click.ip_fmt }}
                td
                  span(v-if="r.click") {{ r.click.created_at }}
                td {{ r.created_at }}
                td {{ r.report_time }}
                td
                  span(v-if="r.is_install") Install
                  span(v-if="!r.is_install") [{{ r.type_id }}] {{ r.type }}
                td.info-buttons
                  a.btn(href="javascript:void(0)", :id="`pop-params-${r.id}`", v-b-tooltip.hover.bottom, title="Params" )
                    i.la.la-info-circle
                  b-popover.params-popover(:target="`pop-params-${r.id}`", placement="left", title="Params")
                    pre.text-small {{ r.params }}

                td.actions
                  button.btn.btn-secondary(v-if="!r.reported", type="button", v-b-tooltip.hover.bottom, title="Report", @click="report(r)")
                    i.la.la-forward
                  button.btn.btn-secondary(v-if="!r.is_adv_conversion", type="button", v-b-tooltip.hover.bottom, title="Convert", @click="convert(r)")
                    i.la.la-dollar-sign
            tfoot
              tr
                td(colspan="11") &nbsp;
                td.text-center {{ summary.payout | toCurrency }}
                td.text-center {{ summary.cost | toCurrency }}
                td(colspan="8") &nbsp;

        paginate(:paginator="paginate", @update-page-size="loadData")

</template>
<style lang="scss">
.params-popover {
  max-width: 350px;
}

.info-buttons {
  a.btn {
    font-size: 16px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    padding: 0;
    text-align: center;
    border-radius: 100%;
  }
}
</style>
<script>
import Vue from 'vue';
import moment from 'moment';

export default {
  name: 'Events',
  computed: {
    USER() {
      return this.$store.state.user;
    },
  },
  async beforeRouteEnter(to, from, next) {
    let dateStartQ = to.query.date_start;

    let dateStartObj = null;
    if (dateStartQ) {
      dateStartObj = moment(dateStartQ, 'YYYY-MM-DD HH:mm');
    }
    if (!dateStartObj || !dateStartObj.isValid()) {
      dateStartObj = moment(
        moment()
          .subtract(-(new Date().getTimezoneOffset() / 60), 'hours')
          .format('DD/MM/YYYY') + ' 00:00',
        'DD/MM/YYYY HH:mm',
      );
    }
    let dateEndQ = to.query.date_end;

    let dateEndObj = null;
    if (dateEndQ) {
      dateEndObj = moment(dateEndQ, 'YYYY-MM-DD HH:mm');
    }
    if (!dateEndObj || !dateEndObj.isValid()) {
      dateEndObj = moment(
        moment()
          .subtract(-(new Date().getTimezoneOffset() / 60), 'hours')
          .format('DD/MM/YYYY') + ' 23:59',
        'DD/MM/YYYY HH:mm',
      );
    }

    let selectedAdvertiserOptions = await Vue.ovData.advertiser.getSelectedOptions(
      to.query.advertiser_id,
    );
    let selectedPublisherOptions = await Vue.ovData.publisher.getSelectedOptions(
      to.query.publisher_id,
    );
    let selectedOfferOptions = await Vue.ovData.offer.getSelectedOptions(to.query.offer_id);
    let selectedPlacementOptions = await Vue.ovData.placement.getSelectedOptions(
      to.query.placement_id,
    );

    next(async (vm) => {
      vm.filters.offer_id = selectedOfferOptions;
      vm.filters.publisher_id = selectedPublisherOptions;
      vm.filters.placement_id = selectedPlacementOptions;
      vm.filters.advertiser_id = selectedAdvertiserOptions;
      vm.filters.datePicker.dateRange.startDate = dateStartObj;
      vm.filters.datePicker.dateRange.endDate = dateEndObj;
      vm.filters.event_name = to.query.event_name || '';

      vm.loadData();
      return vm;
    });
  },
  data() {
    const defaultFilters = {
      keyword: '',
      cohort: false,
      clicks: '',
      conversions_only: false,
      daily: false,
      offer_id: [],
      placement_id: [],
      publisher_id: [],
      advertiser_id: [],
      event_name: '',
      datePicker: {
        timezone: 0,
        dateRange: {
          startDate: null,
          endDate: null,
        },
      },
    };
    return {
      areFiltersOpened: false,
      busy: false,
      paginate: {
        numPages: 0,
        total: 0,
        page: 1,
        limit: 30,
        onPageChange: () => {
          this.loadData();
        },
      },
      records: [],
      event_name: '',
      summary: {
        payout: 0,
        cost: 0,
      },
      placementViewMode: 'mini',
      selectedTab: 'search',
      bucket: [],
      bucketHash: {},
      defaultFilters,
      filters: { ...defaultFilters },
      sortBy: {
        field: 'id',
        direction: 'desc',
        sort: null,
      },
    };
  },
  methods: {
    togglePlacementViewMode() {
      if (this.placementViewMode === 'mini') {
        this.placementViewMode = 'normal';
      } else {
        this.placementViewMode = 'mini';
      }
    },

    appendEntity(record, entityType) {
      let entity = record[entityType];
      if (!entity || !this.filters[entityType + '_id']) {
        return;
      }
      for (let k in this.filters[entityType + '_id']) {
        if (+this.filters[entityType + '_id'].id === +entity.id) {
          return false;
        }
      }

      switch (entityType) {
        case 'placement':
        case 'offer':
          this.filters[entityType + '_id'].push({
            id: entity.id,
            name: `[${entity.id}] ${entity.name}`,
          });
          break;
        case 'advertiser':
        case 'publisher':
          this.filters[entityType + '_id'].push({
            v: entity.id,
            t: `[${entity.id}] ${entity.name}`,
          });
          break;
      }
      this.$forceUpdate();
    },

    async report(r) {
      if (this.busy) {
        return;
      }
      let confirmationMsg = 'Are you sure you want to report this event?';
      if (r.is_adv_conversion) {
        confirmationMsg =
          'This event is a conversion event. Reporting it will also report the conversion. Are you sure you want to proceed?';
      }
      let confirmed = await this.$bvModal.msgBoxConfirm(confirmationMsg);
      if (confirmed) {
        this.busy = true;
        try {
          await this.$ovReq.post('event/report', { id: r.id });
          this.busy = false;
          await this.loadData();
        } catch (e) {
          console.error(e);
        }
      }
    },

    async convert(r) {
      if (this.busy) {
        return;
      }
      let confirmed = await this.$bvModal.msgBoxConfirm('Turn this event to a conversion?');
      if (confirmed) {
        this.busy = true;
        try {
          await this.$ovReq.post('event/generateConversion', { id: r.id });
          this.busy = false;
          await this.loadData();
        } catch (e) {
          console.error(e);
        }
      }
    },

    async bulkReport() {
      if (this.busy) {
        return;
      }
      let recordIds = this.bucket.map((o) => o.id);
      if (this.bucket.length === 0 || !recordIds.length) {
        return this.$ovNotify.error('No events were selected');
      }

      this.busy = true;
      try {
        await this.$ovReq.post('event/bulkReport', { ids: recordIds });
        this.busy = false;
        await this.loadData();
      } catch (e) {
        console.error(e);
      }
      this.busy = false;
    },

    async bulkConvert() {
      if (this.busy) {
        return;
      }
      let recordIds = this.bucket.filter((o) => !o.is_adv_conversion).map((o) => o.id);
      if (this.bucket.length === 0 || !recordIds.length) {
        return this.$ovNotify.error('No unconverted events were selected');
      }

      let alreadyConvertedCount = this.bucket.length - recordIds.length;
      let confirmed;
      if (alreadyConvertedCount > 0) {
        confirmed = await this.$bvModal.msgBoxConfirm(
          'Some of the selected events are already converted and will be ignored. Convert ' +
          recordIds.length +
          ' selected events',
        );
      } else {
        confirmed = await this.$bvModal.msgBoxConfirm(
          'Convert ' + recordIds.length + ' selected events',
        );
      }
      if (!confirmed) {
        return;
      }
      this.busy = true;
      try {
        await this.$ovReq.post('event/bulkConvert', { ids: recordIds });
        this.busy = false;
        await this.loadData();
      } catch (e) {
        console.error(e);
      }
      this.busy = false;
    },

    async loadData(doExport) {
      if (this.busy) {
        return;
      }
      let params = {
        export: doExport ? 1 : 0,
        conversions_only: this.filters.conversions_only ? 1 : 0,
        page: this.paginate.page,
        page_size: this.paginate.limit,
        event_name: this.filters.event_name,
        clicks: this.filters.clicks,
        //click_from: this.filters.click_from ? moment(this.filters.click_from).format('YYYY-MM-DD') : '',

        advertiser_id: this.filters.advertiser_id.map((r) => r.v).join(','),
        publisher_id: this.filters.publisher_id.map((r) => r.v).join(','),

        offer_id: this.filters.offer_id.map((r) => r.id).join(','),
        placement_id: this.filters.placement_id.map((r) => r.id).join(','),

        timezone: this.filters.datePicker.timezone,
        date_start: moment(this.filters.datePicker.dateRange.startDate).format('YYYY-MM-DD HH:mm'),
        date_end: moment(this.filters.datePicker.dateRange.endDate).format('YYYY-MM-DD HH:mm'),
      };
      this.busy = true;
      try {
        // let resp = await this.$ovReq.get('event/getList', {params});
        let resp = await this.$ovReq.post('event/getList', params);
        if (doExport) {
          window.open(resp.path);
        } else {
          this.records = resp.records;
          this.paginate.total = resp.total;
          this.paginate.numPages = Math.max(
            Math.ceil(this.paginate.total / this.paginate.limit),
            1,
          );
          this.summary = resp.summary;
        }
      } catch (e) {
        console.error(e);
      }
      this.busy = false;
    },

    clearBucket() {
      this.bucket = [];
      this.bucketHash = {};
    },
    selectAll() {
      this.records.forEach((r) => {
        if (!this.bucketHash[r.id]) {
          Vue.set(this.bucketHash, r.id, true);
          this.bucket.push(r);
        }
      });
    },
    toggleBucket(r) {
      if (this.bucketHash[r.id]) {
        Vue.set(this.bucketHash, r.id, false);
        for (let i = 0; i < this.bucket.length; i++) {
          if (this.bucket[i].id === r.id) {
            this.bucket.splice(i, 1);
            return;
          }
        }
      } else {
        Vue.set(this.bucketHash, r.id, true);
        this.bucket.push(r);
      }
    },
  },
};
</script>
